import React, { useEffect, useState } from "react";
import s from "./NFTPage.module.scss";
import cx from "classnames";
import Header from "../../components/Header/Header";

import AppConfig from "../../AppConfig";
import NFTCard from "../../components/NFTCard/NFTCard";
import OpenSeaCard from "../../components/OpenSeaCard/OpenSeaCard";
//import abi from "../../helpers/PoP_abi.json";
import WalletHelper from "../../helpers/WalletHelper";
import { useHistory } from "react-router";
import BlockchainHelper from "../../helpers/BlockchainHelper";
import DonateCard from "../../components/DonateCard/DonateCard";
import LOCAL_STORAGE from "../../helpers/localStorage";
import CopyWidget from "../../components/CopyWidget/CopyWidget";
import Footer from "../../components/Footer/Footer";
import { Button } from "reactstrap";

export default function NFTPage({ user }) {
	const readStoredNFTs = (user) => {
		try {
			let stateData = LOCAL_STORAGE.loadState() || {};
			return user?.metamaskAccount && stateData.storedNFTs ? stateData.storedNFTs[user.metamaskAccount.toLowerCase()] : null;
		} catch (e) {
			return null;
		}
	};

	const history = useHistory();
	const [proofs, setProofs] = useState(readStoredNFTs(user) || []);

	useEffect(() => {
		const ts = +new Date();
		let stateData = LOCAL_STORAGE.loadState() || {};
		const lastAccount = stateData.nftAccount;
		const lastCheck = stateData.nftCheckTs;

		if (user?.metamaskAccount && ts - lastCheck > 3000) {
			const readNFTs = () =>
				WalletHelper.getUserNFTs(user?.metamaskAccount).then((data) => {
					if (data.length === 0) {
						history.push("/");
					} else {
						try {
							let stateData = LOCAL_STORAGE.loadState() || {};
							const nfts = stateData.storedNFTs || {};
							nfts[user.metamaskAccount.toLowerCase()] = data;

							LOCAL_STORAGE.saveState({
								...stateData,
								storedNFTs: nfts,
							});
						} catch (e) {
							console.log(e);
						}

						setProofs(data);
					}
				});

			if (lastAccount !== user?.metamaskAccount) {
				LOCAL_STORAGE.saveState({
					...stateData,
					nftAccount: user?.metamaskAccount,
					nftCheckTs: ts,
				});

				BlockchainHelper.switchChain(false).then(() => readNFTs());
			} else {
				LOCAL_STORAGE.saveState({
					...stateData,
					nftCheckTs: ts,
				});

				readNFTs();
			}
		} else {
			LOCAL_STORAGE.saveState({
				...stateData,
				nftCheckTs: ts,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user?.metamaskAccount]);

	const openseaUrl = user?.metamaskAccount && proofs.length > 0 ? AppConfig.OPENSEA_COLLECTION_URL + "/" + proofs[0].id
		: (user?.metamaskAccount ? AppConfig.OPENSEA_URL + user?.metamaskAccount : AppConfig.OPENSEA_COLLECTION_URL);

	return (
		<div className={s.root}>
			<Header user={user} />

			<div className={s.content}>
				<div className={s.title}>
					You've got a Proof of Philanthropy Dynamic NFT!
				</div>

				<div className={s.infos}>
					<div className={cx(s.description, s.mb)}>
						The connected wallet already has a Proof of Philanthropy Dynamic NFT
					</div>

					<div className={cx(s.description, s.mt, s.mb)}>
						After you make donations to:
					</div>

					<CopyWidget text={AppConfig.DONATIONS_ADDRESS} />

					<div className={cx(s.description, s.mb)}>
						You'll see your NFT power-up.
					</div>
					<div className={cx(s.description, s.mb)}>
						Thanks for supporting our mission to combat aging!
					</div>
				</div>

				{/* NFTS */}
				<div className={proofs.length > 1 ? s.cards : null}>
					{proofs.map((data, index) => (
						<NFTCard data={data} key={index} />
					))}
				</div>

				<div>
					&nbsp;
				</div>

				<div className={cx(s.description, s.mt, s.mb)}>
					<Button
						color="primary"
						className={"btn-border"}
						onClick={() => window.open(openseaUrl, "_blank")}
					>
						View NFT on OpenSea
					</Button>
				</div>

				<div>
					&nbsp;
				</div>

				{/* COINS */}
				<DonateCard />

				{/* OPEN SEA */}
				<OpenSeaCard user={user} nfts={proofs} />

				<Footer />
			</div>
		</div>
	);
}
