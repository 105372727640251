import React from "react";

import s from "./NewSitePage.module.scss";

import Header from "../../components/Header/Header";
import AppConfig from "../../AppConfig";
import AppImages from "../../AppImages";

const fileList = [
  "Landing Animation_v002_VP9.webm",
  "Landing Animation_v002-1.mov"
  // Add more files as needed
];

export default function NewSitePage({ user }) {
  const Images = AppImages.Help;

  return (
    <div className={s.root}>
      <Header user={user} showHome />

      <div className={s.content}>
        <ul>
          {fileList.map((file) => (
            <li key={file}>
              <a href={`/NewSite/${file}`} target="_blank" rel="noopener noreferrer">
                {file}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
