import React, { useState } from "react";

import s from "./DonateCard.module.scss";
import AppConfig from "../../AppConfig";
import AppImages from "../../AppImages";
import { Input, TabPane } from "reactstrap";
import abi from "../../weave/ERC20.json";
import LOCAL_STORAGE from "../../helpers/localStorage";

const DEFAULT_AMOUNT = 1;

const ETH_CHAIN_ID = "0x1";
const POLYGON_CHAIN_ID = "0x89";

const ETH_MAINCHAIN = {
	chainId: ETH_CHAIN_ID,
	chainName: "Ethereum Mainnet",
	nativeCurrency: {
		name: "ETH",
		symbol: "ETH",
		decimals: 18,
	},
	rpcUrls: ["https://ethereum.publicnode.com"],
	blockExplorerUrls: ["https://etherscan.io/"],
};

const POLYGON_MAINCHAIN = {
	chainId: POLYGON_CHAIN_ID,
	chainName: "Polygon Mainnet",
	nativeCurrency: {
		name: "MATIC",
		symbol: "MATIC",
		decimals: 18,
	},
	rpcUrls: ["https://polygon-rpc.com"],
	blockExplorerUrls: ["https://polygonscan.com/"],
};

const BLOCKCHAINS = {
	ethereum: ETH_MAINCHAIN,
	polygon: POLYGON_MAINCHAIN,
};

export default function DonateCard() {
	const [value, setValue] = useState(DEFAULT_AMOUNT);

	const { ethereum } = window;

	const tokens = [];
	const chains = Object.keys(AppConfig.TOKENS);
	chains.forEach((chain) =>
		Object.keys(AppConfig.TOKENS[chain]).forEach((t) =>
			tokens.push([chain, t, AppConfig.TOKENS[chain][t]])
		)
	);

	const switchNetwork = async (chain) => {
		try {
			if (1 * ethereum.networkVersion === chain.chainId) {
				return;
			}
			await ethereum.request({
				method: "wallet_switchEthereumChain",
				params: [{ chainId: chain.chainId }],
			});
		} catch (switchError) {
			const msg = switchError?.message || "";
			if (!msg.includes("already pending") && !msg.includes("User rejected")) {
				try {
					if (1 * ethereum.networkVersion === chain.chainId) {
						return;
					}

					await ethereum.request({
						method: "wallet_addEthereumChain",
						params: [chain],
					});
				} catch (error) {
					console.debug(error);
				}
			}
		}
	};

	const sendTokens = async (chain, tokenName, tokenAddr) => {
		try {
			const blockchain = BLOCKCHAINS[chain];
			const accounts = await ethereum.request({
				method: "eth_requestAccounts",
			});
			const account = accounts[0];

			let stateData = LOCAL_STORAGE.loadState() || {};
			const ts = +new Date();
			LOCAL_STORAGE.saveState({
				...stateData,
				switchChainTs: ts,
				switchChain: blockchain,
			});

			if (1 * ethereum.networkVersion !== blockchain.chainId) {
				await switchNetwork(blockchain);
			}

			const isChainToken =
				(chain === "polygon" && tokenName === "MATIC") ||
				(chain === "ethereum" && tokenName === "ETH");
			let amount = value;

			if (("" + amount).startsWith(".")) {
				amount = "0" + amount;
			}

			if (isChainToken) {
				const value = (
					amount * Math.pow(10, blockchain.nativeCurrency.decimals)
				).toString(16);

				ethereum
					.request({
						method: "eth_sendTransaction",
						params: [
							{
								from: account,
								to: AppConfig.DONATIONS_ADDRESS,
								gas: `0x${AppConfig.TRANSACTION_GAS_LIMIT.toString(16)}`, //safe margin, ~21k actually needed
								value,
							},
						],
					})
					.then((txHash) => console.log(txHash))
					.catch((error) => console.error(error));
			} else {
				console.log(tokenAddr);
				const contract = await new window.web3.eth.Contract(abi, tokenAddr);
				const decimals = await contract.methods.decimals().call();
				const value = "" + (amount * Math.pow(10, decimals));

				ethereum.request({
					method: 'eth_sendTransaction',
					params: [{
						from: account,
						to: tokenAddr,
						gas: `0x${AppConfig.TRANSACTION_GAS_LIMIT.toString(16)}`, //safe margin, ~65k actually needed
						data: contract.methods.transfer(AppConfig.DONATIONS_ADDRESS, value).encodeABI()
					}]
				})
					.then((txHash) => console.log(txHash))
					.catch((error) => console.error(error));

				// let send = await contract.methods.transfer(AppConfig.DONATIONS_ADDRESS, value).send({
				// 	from: account,
				// 	to: AppConfig.DONATIONS_ADDRESS,
				// 	data: contract.methods.transfer(AppConfig.DONATIONS_ADDRESS, value).encodeABI()
				// });
				// console.log(send);
				// const receipt = await new window.web3.eth.getTransactionReceipt(send.transactionHash);
				// console.log(receipt);
			}
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<div className={s.root}>
			<div className={s.donateInput}>
				<p className={s.text}>You can donate</p>
				<Input
					value={value}
					onChange={(e) => setValue(e.target.value)}
					type="numeric"
					placeholder="Please enter amount..."
				/>
			</div>

			<div className={s.coins}>
				{tokens.map((t, index) => (
					<TabPane
						tabId={index}
						key={index}
						onClick={() => sendTokens(t[0], t[1], t[2])}
						className={s.coinContainer}
					>
						<>
							<img src={AppImages[t[1]]} alt="..." className={s.coinLogo} />
							<img src={AppImages[t[0]]} alt="..." className={s.chainLogo} />
							<p className={s.name}>{t[1]}</p>
						</>
					</TabPane>
				))}
			</div>
		</div>
	);
}
