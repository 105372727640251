import React, { useEffect, useState } from "react";

import s from "./AdminMintPage.module.scss";

import InputWidget from "../../../components/InputWidget/InputWidget";
import MintButton from "../../../components/MintButton/MintButton";
import AppMessages from "../../../AppMessages";
import LOCAL_STORAGE from "../../../helpers/localStorage";
import Header from "../../../components/Header/Header";
import AppImages from "../../../AppImages";

export default function AdminMintPage({ user }) {
	const [address, setAddress] = useState("");
	const [validationError, setValidationError] = useState(null);

	useEffect(() => {
		let stateData = LOCAL_STORAGE.loadState() || {};

		setAddress(stateData.recipient || "");
	}, [user?.metamaskAccount]);

	return (
		<div className={s.root}>
			<Header user={user} showHome={true} />

			<div className={s.content}>
				<div className={s.title}>Connected!</div>

				<div className={s.description}>
					Perfect, now enter the recipient's wallet address and click below to
					mint and send them their very own NFT!
				</div>
				<div className={s.description}>
					Thanks for supporting our mission to fight aging!
				</div>

				<div className={s.inputContainer}>
					<InputWidget
						titleClass={s.inputTitle}
						class={s.input}
						title="NFT Recipient Wallet Address"
						className={s.input}
						value={address || ""}
						placeholder="0x0000000000000000000000000000000000000000"
						onChange={(addr) => {
							let stateData = LOCAL_STORAGE.loadState() || {};
							LOCAL_STORAGE.saveState({
								...stateData,
								recipient: addr,
							});

							setAddress(addr);
						}}
						validationError={validationError}
					/>
				</div>

				<div className={s.buttons}>
					<MintButton
						text={AppMessages.ADMIN_MINT_BUTTON_TEXT}
						selfMint={false}
						receiverAddress={address}
						onValidate={setValidationError}
					/>
				</div>

				<div className={s.image}>
					<img src={AppImages.MagicHatImg} alt="..." />
				</div>
			</div>
		</div>
	);
}
