import React, { useState, useEffect } from "react";

import Web3 from "web3";
import { Switch, Route, Redirect } from "react-router";
import { useMetaMask } from "metamask-react";

import ConnectMetamaskPage from "./ConnectMetamaskPage/ConnectMetamaskPage";
import MintPage from "./MintPage/MintPage";
import NFTPage from "./NFTPage/NFTPage";

import LifespanAdminApp from "./LifespanAdminApp";
import WalletHelper from "../helpers/WalletHelper";
import BlockchainHelper from "../helpers/BlockchainHelper";
import HelpPage from "./HelpPage/HelpPage";
import NewSite from "./NewSitePage/NewSitePage";
import LOCAL_STORAGE from "../helpers/localStorage";

export default function Lifespan() {
	const readStoredNFTs = (wallet) => {
		try {
			if (wallet) {
				let stateData = LOCAL_STORAGE.loadState() || {};
				return stateData.storedNFTs ? stateData.storedNFTs[wallet.toLowerCase()] : null;
			} else {
				return null;
			}
		} catch (e) {
			return null;
		}
	};

	const [user, setUser] = useState(null);
	const [hasNFT, setHasNFT] = useState(null);

	const meta = useMetaMask();
	const isLoggedIn = !!meta.account;

	useEffect(() => {
		const wallet =
			meta.account && meta.account.length > 0
				? Web3.utils.toChecksumAddress(meta.account)
				: null;

		if (meta?.status === "connected") {
			setUser({
				metamaskAccount: wallet,
				avatar: "metamask",
				id: 13, // TODO:
			});
		} else if (meta?.status === "initializing") {
			setUser(null);
		}

		const storedNFTs = readStoredNFTs(meta.account);
		setHasNFT(storedNFTs != null);

		BlockchainHelper.switchChain(false).then(() =>
			WalletHelper.checkIfUserHasNFT(wallet).then((r) => {
				setHasNFT(r);
			})
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [meta.account]);

	// ------------------------------------- METHODS -------------------------------------
	const PrivateRoute = ({ isLoggedIn, hasNFT, ...props }) => {
		return isLoggedIn ? (
			hasNFT ? (
				<Redirect to={"/nft"} />
			) : (
				<Route {...props} />
			)
		) : (
			<Redirect to={"/login"} />
		);
	};

	const AnonymousRoute = ({ isLoggedIn, ...props }) => {
		return isLoggedIn ? <Redirect to={"/"} /> : <Route {...props} />;
	};

	return (
		<Switch>
			<Route
				path={["/admin"]}
				render={() => <LifespanAdminApp user={user} />}
			/>

			<AnonymousRoute
				isLoggedIn={isLoggedIn}
				path="/login"
				exact
				component={ConnectMetamaskPage}
			/>

			<Route path="/newsite/files" exact render={() => <NewSite user={user} />} />

			<Route path="/help" exact render={() => <HelpPage user={user} />} />

			<PrivateRoute
				isLoggedIn={isLoggedIn}
				exact
				hasNFT={hasNFT}
				path={"/"}
				render={() => <MintPage user={user} />}
			/>

			<PrivateRoute
				isLoggedIn={isLoggedIn}
				path={"/nft"}
				exact
				render={() => <NFTPage user={user} />}
			/>
		</Switch>
	);
}
