import LOCAL_STORAGE from "./localStorage";

const POLYGON_CHAIN_ID = "0x89";
const POLYGON_TESTNET_ID = "0x13881";

const POLYGON_MAINCHAIN = {
	chainId: POLYGON_CHAIN_ID,
	chainName: "Polygon Mainnet",
	nativeCurrency: {
		name: "MATIC",
		symbol: "MATIC",
		decimals: 18,
	},
	rpcUrls: ["https://polygon-rpc.com"],
	blockExplorerUrls: ["https://polygonscan.com/"],
};

const POLYGON_TESTNET = {
	chainId: POLYGON_TESTNET_ID,
	chainName: "Mumbai Testnet",
	nativeCurrency: {
		name: "MATIC",
		symbol: "MATIC",
		decimals: 18,
	},
	rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
	blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
};

const TOKENS = {
};

// ------------------------------------- METHODS -------------------------------------
const getTokenAddress = ({ token }) => {
	return TOKENS[token];
};

const getChain = (useTestnet) => {
	return useTestnet ? POLYGON_TESTNET : POLYGON_MAINCHAIN;
};

const getChainId = (useTestnet) => {
	return parseInt(useTestnet ? POLYGON_TESTNET_ID : POLYGON_CHAIN_ID, 16);
};

const switchChain = async (useTestnet) => {
	try {
		const chain = BlockchainHelper.getChain(useTestnet);

		let stateData = LOCAL_STORAGE.loadState() || {};
		const ts = +new Date();
		if (stateData.switchChain && ts - stateData.switchChainTs < 120 * 1000) {
			if (stateData.switchChain.chainId !== chain.chainId) {
				//skip switching chain, it's a recent payment
				return;
			}
		}

		const {ethereum} = window;
		const switchNetwork = async () => {
			//console.debug(chain);
			try {
				if (1 * ethereum.networkVersion === chain.chainId) {
					return;
				}
				await ethereum.request({
					method: "wallet_switchEthereumChain",
					params: [{chainId: chain.chainId}],
				});
			} catch (switchError) {
				const msg = switchError?.message || "";
				if (!msg.includes("already pending") && !msg.includes("User rejected")) {
					try {
						if (1 * ethereum.networkVersion === chain.chainId) {
							return;
						}

						await ethereum.request({
							method: "wallet_addEthereumChain",
							params: [chain],
						});
					} catch (error) {
						console.debug(error);
					}
				}
			}
		};

		const productChainId = getChainId(useTestnet);
		if (1 * ethereum.networkVersion !== productChainId) {
			await switchNetwork();
		}
	} catch (e) {
		console.log(e);
	}
}

const BlockchainHelper = {
	getChainId,
	getChain,
	getTokenAddress,
	switchChain
};

export default BlockchainHelper;
