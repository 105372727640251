import abi from "./PoP_abi.json";

import AppConfig from "../AppConfig";
import Web3 from "web3";
import WeaveHelper from "../weave/weavehelper";
import defaultConfig from "../weave/lifespan.json";

const checkWeb3 = () => {
	if (window.ethereum) {
		window.web3 = new Web3(window.ethereum);
		window.ethereum.enable();
	}
};

const checkIfUserHasNFT = async (wallet) => {
	try {
		if (wallet) {
			checkWeb3();

			const contract = await new window.web3.eth.Contract(
				abi,
				AppConfig.NFT_TOKEN_URI
			);
			let count = await contract.methods.balanceOf(wallet).call();
			console.log("Wallet " + wallet + " has " + (count ? count : 0)  + " NFT" + (!count || count > 1 ? "s" : ""));
			return count > 0;
		} else {
			return false;
		}
	} catch (e) {
		console.log(e);
		return false;
	}
};

const mint = async (wallet, receiverAddress) => {
	try {
		if (wallet) {
			try {
				checkWeb3();

				const contract = await new window.web3.eth.Contract(
					abi,
					AppConfig.NFT_TOKEN_URI
				);
				let mint = await contract.methods.mintNFT(receiverAddress).send({
					from: wallet,
					to: receiverAddress,
					gasPrice: AppConfig.DEFAULT_GAS_PRICE,
					gasLimit: AppConfig.GAS_LIMIT,
				});
				console.log(mint);
				const receipt = await new window.web3.eth.getTransactionReceipt(
					mint.transactionHash
				);
				console.log(receipt);

				return receipt;
			} catch (e) {
				console.log("Failed minting");
				console.log(e);
				return false;
			}
		} else {
			return false;
		}
	} catch (e) {
		console.log(e);
		return false;
	}
};

const getBalanceOf = async (wallet) => {
	try {
		if (wallet) {
			checkWeb3();

			const contract = await new window.web3.eth.Contract(
				abi,
				AppConfig.NFT_TOKEN_URI
			);
			let count = await contract.methods.balanceOf(wallet).call();
			console.log("Wallet " + wallet + " has " + count + " NFTs");
			return count;
		} else {
			return 0;
		}
	} catch (e) {
		return 0;
	}
}
const readHistory = async (wallet) => {
	try {
		const result = [];

		checkWeb3();

		if (wallet) {
			const contract = await new window.web3.eth.Contract(
				abi,
				AppConfig.NFT_TOKEN_URI
			);
			let nfts = await contract.methods.tokensOfOwner(wallet).call();

			console.log(nfts)
			if (nfts) {
				const [nodeApi, session] = await WeaveHelper.weaveLogin(defaultConfig.sideChain);

				for (let i = 0; i < nfts.length; i++) {
					const id = nfts[i];
					const filter = new WeaveHelper.Helper.Filter(WeaveHelper.Helper.FilterOp.eq("name", "" + id), {"id": "ASC"}, null, ["name"]);
					const data = await nodeApi.read(session, defaultConfig.nfts_scope, defaultConfig.nfts_table, filter, WeaveHelper.Helper.Options.READ_DEFAULT_NO_CHAIN);
					if (data.data) {
						//console.log(data)
						data.data.forEach((r) => result.push(r));
					}
				}
			}
			return result;
		} else {
			const [nodeApi, session] = await WeaveHelper.weaveLogin(defaultConfig.sideChain);
			const filter = new WeaveHelper.Helper.Filter(null, {"id": "ASC"}, null, ["name"]);
			const data = await nodeApi.read(session, defaultConfig.nfts_scope, defaultConfig.nfts_table, filter, WeaveHelper.Helper.Options.READ_DEFAULT_NO_CHAIN);
			if (data.data) {
				//console.log(data)
				data.data.forEach((r) => result.push(r));
			}
			return result;
		}
	} catch (e) {
		console.log(e);
		return [];
	}
}

const readNFTData = async (id) => {
	let result = null;
	try {
		const [nodeApi, session] = await WeaveHelper.weaveLogin(defaultConfig.sideChain);
		const filter = new WeaveHelper.Helper.Filter(WeaveHelper.Helper.FilterOp.eq("name", "" + id), {"id": "ASC"}, null, ["name"]);
		const data = await nodeApi.read(session, defaultConfig.nfts_scope, defaultConfig.nfts_table, filter, WeaveHelper.Helper.Options.READ_DEFAULT_NO_CHAIN);
		if (data.data && data.data.length > 0) {
			result = data.data[0];
		}
	} catch (e) {
		console.log(e);
	}
	return result;
}

const getUserNFTs = async (wallet) => {
	try {
		if (wallet) {
			checkWeb3();

			const contract = await new window.web3.eth.Contract(
				abi,
				AppConfig.NFT_TOKEN_URI
			);
			let owned = await contract.methods.tokensOfOwner(wallet).call();
			const nfts = [];
			if (owned.length > 0) {
				for (let i in owned) {
					try {
						let uri = await contract.methods.tokenURI(owned[i]).call();
						//console.log(uri);
						const data = await fetch(uri, {method: "GET"}).then((response) => {
							if (!response.ok) {
								return null;
							} else {
								return response.json();
							}
						});
						data.id = owned[i];
						nfts.push(data);
					} catch (error) {
						console.log(error);
					}
				}
			}
			return nfts;
		} else {
			return [];
		}
	} catch (e) {
		console.log(e);
		return [];
	}
};

const verifyRowProofs = async (backendRow) => {
	try {
		const [nodeApi] = await WeaveHelper.weaveLogin(defaultConfig.sideChain);

		return verifyIntegritySignature(nodeApi, backendRow) && verifyComputeTaskSignatures(nodeApi, backendRow);
	} catch (e) {
		console.log(e);
		return false;
	}
}

const verifyComputeTaskSignatures = async (nodeApi, backendRow) => {
	let computeSignatureObj = JSON.parse(backendRow.data)

	let toSign = 
		(computeSignatureObj.taskId ? computeSignatureObj.taskId : "null") + "\n" +
		(computeSignatureObj.timestamp ? computeSignatureObj.timestamp : "null") + "\n" +
		(computeSignatureObj.inputHash ? computeSignatureObj.inputHash : "null") + "\n" +
		(computeSignatureObj.computeHash ? computeSignatureObj.computeHash : "null") + "\n" +
		(computeSignatureObj.paramsHash ? computeSignatureObj.paramsHash : "null") + "\n" +
		(computeSignatureObj.outputHash ? computeSignatureObj.outputHash : "null");
	let serverSigKey = await nodeApi.sigKey();
	return await nodeApi.verifyKeySignature(serverSigKey.data, computeSignatureObj.sig, toSign);
}

const verifyIntegritySignature = async (nodeApi, backendRow) => {
	let integritySignature = backendRow.sig;
	let integritySigObj = JSON.parse(integritySignature)[0].sig;;
	let toSignObj = {pubKey: integritySigObj.pubKey, recordsHash: integritySigObj.recordsHash};
	let toSign = JSON.stringify(toSignObj);
	let serverSigKey = await nodeApi.sigKey();
	let signatureMatch = await nodeApi.verifyKeySignature(serverSigKey.data, integritySigObj.sig, toSign);
	if (!signatureMatch) {
		return false;
	}

	let recordString = JSON.stringify([null,null,null,null, backendRow.data]);
	let hash = nodeApi.client.keyExchange.signRequest(nodeApi.client.apiContext.seedHex, recordString);
	hash = nodeApi.client.keyExchange.signRequest(nodeApi.client.apiContext.seedHex, "null\n" + hash);

	return hash === JSON.parse(backendRow.sig)[0].sig.recordsHash;
}

const getComputeProofs = async (writesOnly) => {
	try {
		const [nodeApi, session] = await WeaveHelper.weaveLogin(defaultConfig.sideChain);

		const filter = new WeaveHelper.Helper.Filter(null, {"id": "DESC"}, AppConfig.PROOFS_LIMIT);
		let readResponse = await nodeApi.read(session, defaultConfig.nfts_scope, defaultConfig.proofs_table, filter, WeaveHelper.Helper.Options.READ_DEFAULT_NO_CHAIN);

		if (!readResponse || readResponse.res === "err") {
			return [];
		}

		let result = [];
		for (let i = 0; i < readResponse.data.length; i++) {
			try {
				let backendRow = readResponse.data[i];
				let backendRowData = JSON.parse(backendRow.data);
				if (writesOnly && (!backendRowData.writes || backendRowData.writes.length === 0 || backendRowData.writes === "[]")) {
					continue;
				}
				let row = {
					id: backendRow.id,
					timestamp: Number(backendRow.ts),
					publicKey: backendRowData.nodePubKey,
					integrityGuarantee: JSON.parse(backendRow.sig)[0].sig.sig,
					data: backendRow.data,
					backendRow: backendRow
				};
				result.push(row);
			} catch (e) {
				console.log(e);
			}
		}
		return result;
	} catch (e) {
		console.log(e);
		return [];
	}
};

const WalletHelper = {
	checkIfUserHasNFT,
	mint,
	getUserNFTs,
	readHistory,
	readNFTData,
	getComputeProofs,
	getBalanceOf,
	verifyRowProofs
};

export default WalletHelper;
