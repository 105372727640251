import React from "react";

import s from "./HelpPage.module.scss";

import Header from "../../components/Header/Header";
import AppConfig from "../../AppConfig";
import AppImages from "../../AppImages";

export default function HelpPage({ user }) {
	const Images = AppImages.Help;

	return (
		<div className={s.root}>
			<Header user={user} showHome />

			<div className={s.content}>
				{/* MINT MEANING */}
				<div className={s.section}>
					<div className={s.title}>What does it mean to "mint" an NFT?</div>

					<div className={s.text}>
						Minting an NFT means to create a unique digital asset that is stored
						on a blockchain in your Web3 "wallet." A web3 wallet is like a
						digital bank account for your cryptocurrency and NFTs. A blockchain
						is a secure database that is shared by many computers. This means
						that the NFT is stored in your wallet in a way that is tamper-proof
						and cannot be edited.
						{`\n\n`}
						When you mint an NFT, you are essentially creating a digital
						certificate of ownership for your asset. This certificate of
						ownership is stored on the blockchain and can be verified by anyone.
						This means that you can be sure that your NFT is unique and that you
						are the rightful owner.
						{`\n\n`}
						For more info, we recommend checking out this article on{" "}
						<a
							href={AppConfig.OPEN_SEA_MINTING}
							target="_blank"
							rel="noreferrer"
						>
							OpenSea.io
						</a>
					</div>
				</div>

				{/* MINTING */}
				<div className={s.section}>
					<div className={s.title}>How do I mint the NFT?</div>

					<ol>
						<li className={s.text}>
							First, you will need a Metamask Wallet to mint an NFT. If you
							already have a wallet, skip to step 2. {`\n`}
							Getting a wallet is free and easy! You can download Metamask for
							most browsers, iOS, and Android{" "}
							<a
								href={AppConfig.METAMASK_DOWNLOAD_URL}
								target="_blank"
								rel="noreferrer"
								className={s.underline}
							>
								here on Metamask's website
							</a>
						</li>
						<li className={s.text}>
							Once you have a wallet, press the "Connect with Metamask" button
							on our{" "}
							<a href={"/"} className={s.underline}>
								homepage
							</a>{" "}
						</li>
						<li className={s.text}>
							Then, click the big blue button on our{" "}
							<a href={"/"} className={s.underline}>
								homepage
							</a>{" "}
							that says "Click to Mint NFT"
							<p className={s.note}>
								Please note that if you are using a mobile phone, you'll need to
								do this from the Metamask app browser -{" "}
								<a href={"/"} className={s.underline}>
									click this link to open our homepage in the Metamask Browser
									on your phone
								</a>
							</p>
						</li>
						<li className={s.text}>
							You'll notice this triggers a popup from Metamask asking you to
							confirm the transaction While the starter black-level NFT is free,
							there will be "gas fees" that need to be paid with MATIC to get
							the NFT. Gas fees are the fees that you pay to miners to process
							your transactions on the blockchain. You can read more about them
							here on{" "}
							<a
								href={AppConfig.OPEN_SEA_MINTING}
								target="_blank"
								rel="noreferrer"
							>
								OpenSea.io
							</a>
						</li>
						<li className={s.text}>
							Once you confirm the transaction, the starter black-level NFT will
							be transferred to your wallet.
						</li>
					</ol>
				</div>

				{/* DONATE */}
				<div className={s.section}>
					<div className={s.title}>How can I donate funds?</div>

					<div className={s.text}>
						Once you've minted your NFT, input the amount you would like to
						donate and click the panel for the currency you would like to donate
						in on the{" "}
						<a href={"/"} className={s.underline}>
							homepage
						</a>
						. After you donate, please be patient, and you should see your NFT
						power up!
					</div>
				</div>

				{/* VIEW NFT */}
				<div className={s.section}>
					<div className={s.title}>How do I view the NFT?</div>
					<div className={s.text}>
						You should be able to view your NFT on{" "}
						<a href={"/"} className={s.underline}>
							our homepage
						</a>{" "}
						after connecting with your Metamask wallet. You can also view the
						NFT on Opensea.io on your desktop computer or mobile phone. {`\n\n`}
					</div>

					<div className={s.instructionsHeader}>OpenSea On mobile:</div>
					<ol>
						<li className={s.text}>
							Click on the Browser icon (4th) on the bottom of the app and
							navigate to opensea.io
						</li>
						<li className={s.text}>
							Click on the hamburger menu on the top left
						</li>
						<li className={s.text}>Click on Account, then Profile</li>
						<li className={s.text}>
							You'll see your profile with your collections!
						</li>
					</ol>
					<img src={Images.ViewNFT} alt="..." className={s.image} />

					<div className={s.instructionsHeader}>OpenSea On desktop:</div>
					<ol>
						<li className={s.text}>While connected to your Metamask wallet</li>
						<li className={s.text}>Navigate to opensea.io/account</li>
						<li className={s.text}>
							Or to opensea.io and choose "Profile" from under your user avatar:
						</li>
					</ol>
					<img src={Images.WiewProfile} alt="..." className={s.image} />
				</div>

				{/* IMPORT */}
				<div className={s.section}>
					<div className={s.title}>
						How do I import my NFT to the Metamask Mobile App Wallet?
					</div>
					<ol>
						<li className={s.text}>
							Click on the Wallet icon (1st) on the bottom of the app
						</li>
						<li className={s.text}>
							Enter your address & ID -- your address & ID are visible in the
							URL of the NFT page on OpenSea:
							<img src={Images.GetAddress} alt="..." className={s.image} />
							<p className={s.copyText}>or you can copy them from below:</p>
						</li>
						<li className={s.text}>
							Click Import and be patient, it should show up shortly in the NFTs
							tab!
							<img src={Images.ImportNFT} alt="..." className={s.image} />
						</li>
					</ol>
				</div>

				{/* OPENSEA */}
				<div className={s.section}>
					<div className={s.title}>I don't see my NFT on OpenSea!</div>

					<ol>
						<li className={s.text}>
							Click the "..." icon at the top right of the screen where your NFT
							is displayed
						</li>

						<li className={s.text}>
							Click "Refresh metadata" to display the most recent version of
							your Dynamic NFT
						</li>
					</ol>
					<img src={Images.OpenSeaImg} alt="..." className={s.image} />
				</div>
			</div>
		</div>
	);
}
