const NFT_ADDRESS = "0xdF230dC0d5e4c6bBA9f1570A58bE8120a33dc50F";

const AppConfig = {
	AppName: "Lifespan.io",
	baseUrl: "/",

	METAMASK_DOWNLOAD_URL: "https://metamask.io/download/",
	TERMS_URL: "https://weavechain.io/privacy-policy",
	PRIVACY_POLICY_URL: "https://weavechain.io/gdpr",
	NFT_TOKEN_URI: NFT_ADDRESS,

	LIFESPAN_URL: "https://www.lifespan.io/crypto",
	
	APP_TITLE: "Proof of Philanthropy",
	CURRENCY: "USDC",
	
	DEFAULT_GAS_PRICE: '150000000000',
	GAS_LIMIT: '5000000',
	TRANSACTION_GAS_LIMIT: '200000',

	PAGE_SIZE: 50,

	DONATIONS_ADDRESS: "0xCec4B18107d8AF27fa1395315e4c002343b6a8c2",
	OPENSEA_URL: "https://opensea.io/",
	OPENSEA_COLLECTION_URL: "https://opensea.io/assets/matic/" + NFT_ADDRESS,

	TOKENS: {
		"ethereum": {
			"ETH": '0x0000000000000000000000000000000000000000',
			"USDC": '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
			"VITA": '0x81f8f0bb1cB2A06649E51913A151F0E7Ef6FA321',
			"MATIC": '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
			"USDT": '0xdAC17F958D2ee523a2206206994597C13D831ec7',
		},
		"polygon": {
			"MATIC": '0x0000000000000000000000000000000000001010',
			"USDC": '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
			"USDT": '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
			"WETH": '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
		}
	},

	PROOFS_LIMIT: 5000
};

export default AppConfig;
