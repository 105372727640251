import React from "react";

import s from "./Footer.module.scss";
import AppConfig from "../../AppConfig";
import Logo from "../Logo/Logo";

export default function Footer() {
	return (
		<div className={s.root}>
			<div className={s.logo}>
				<Logo url={AppConfig.LIFESPAN_URL} />
			</div>

			<div className={s.note}>
				Note these transactions are a donation to a 501c3 organization (Tax EIN:
				46-5328762) and thus may be eligible for tax deduction . Consult a tax
				professional about your individual situation. If you would like a tax
				receipt for your donation, please email your name, wallet and the amount
				of your donation to info@lifespan.io.
			</div>
		</div>
	);
}
