import React from "react";

export default function HistoryIcon({
	color = "#80B63C",
	width = 48,
	height = 48,
}) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_314_765)">
				<path
					d="M24 16C22.4178 16 20.871 16.4692 19.5554 17.3482C18.2398 18.2273 17.2145 19.4767 16.609 20.9385C16.0035 22.4003 15.845 24.0089 16.1537 25.5607C16.4624 27.1126 17.2243 28.538 18.3431 29.6569C19.462 30.7757 20.8874 31.5376 22.4393 31.8463C23.9911 32.155 25.5997 31.9965 27.0615 31.391C28.5233 30.7855 29.7727 29.7602 30.6518 28.4446C31.5308 27.129 32 25.5823 32 24C31.9937 21.8802 31.1488 19.8491 29.6499 18.3501C28.1509 16.8512 26.1198 16.0063 24 16ZM24 30C22.8133 30 21.6533 29.6481 20.6666 28.9888C19.6799 28.3295 18.9109 27.3925 18.4567 26.2961C18.0026 25.1997 17.8838 23.9933 18.1153 22.8295C18.3468 21.6656 18.9182 20.5965 19.7574 19.7574C20.5965 18.9182 21.6656 18.3468 22.8295 18.1153C23.9933 17.8838 25.1997 18.0026 26.2961 18.4567C27.3925 18.9108 28.3295 19.6799 28.9888 20.6666C29.6481 21.6533 30 22.8133 30 24C29.9953 25.5898 29.3616 27.1132 28.2374 28.2374C27.1132 29.3616 25.5898 29.9953 24 30Z"
					fill={color}
				/>
				<path
					d="M27.5 23H25V20.5C25 20.2348 24.8946 19.9804 24.7071 19.7929C24.5196 19.6054 24.2652 19.5 24 19.5C23.7348 19.5 23.4804 19.6054 23.2929 19.7929C23.1054 19.9804 23 20.2348 23 20.5V24C23 24.2652 23.1054 24.5196 23.2929 24.7071C23.4804 24.8946 23.7348 25 24 25H27.5C27.7652 25 28.0196 24.8946 28.2071 24.7071C28.3946 24.5196 28.5 24.2652 28.5 24C28.5 23.7348 28.3946 23.4804 28.2071 23.2929C28.0196 23.1054 27.7652 23 27.5 23Z"
					fill={color}
				/>
			</g>
			<rect
				x="1"
				y="1"
				width="46"
				height="46"
				rx="3"
				stroke={color}
				strokeWidth="2"
			/>
			<defs>
				<clipPath id="clip0_314_765">
					<rect
						width="16"
						height="16"
						fill="white"
						transform="translate(16 16)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
}
