import React, { useEffect } from "react";
import cx from "classnames";
import s from "./MintPage.module.scss";

import Header from "../../components/Header/Header";
import AppConfig from "../../AppConfig";
import MintButton from "../../components/MintButton/MintButton";
//import DonateCard from "../../components/DonateCard/DonateCard";
import AppImages from "../../AppImages";
import { useHistory } from "react-router";
import WalletHelper from "../../helpers/WalletHelper";
import BlockchainHelper from "../../helpers/BlockchainHelper";
import CopyWidget from "../../components/CopyWidget/CopyWidget";
import Footer from "../../components/Footer/Footer";

export default function MintPage({ user }) {
	const history = useHistory();

	useEffect(() => {
		if (user?.metamaskAccount) {
			BlockchainHelper.switchChain(false).then(() =>
				WalletHelper.checkIfUserHasNFT(user?.metamaskAccount).then((data) => {
					if (data) {
						history.push("/nft");
					}
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user?.metamaskAccount]);

	return (
		<div className={s.root}>
			<Header user={user} />

			<div className={s.content}>
				<div className={s.title}>Connected!</div>

				<div className={s.description}>
					Perfect, now click below to mint your very own NFT!
				</div>

				{/* <DonateCard /> */}

				<div className={cx(s.description, s.mt, s.mb)}>
					After you make donations to:
				</div>

				<CopyWidget text={AppConfig.DONATIONS_ADDRESS} />

				<div className={cx(s.description, s.mb)}>
					You'll see your NFT power-up.
				</div>

				<div className={cx(s.description, s.mb)}>
					Thanks for supporting our mission to combat aging!
				</div>

				<div className={s.buttons}>
					<MintButton />
				</div>

				<div className={s.image}>
					<img src={AppImages.MagicHatImg} alt="..." />
				</div>

				<Footer />
			</div>
		</div>
	);
}
