import React from "react";
import { Link } from "react-router-dom";
//import { useRouteMatch } from "react-router";

import classNames from "./Logo.module.scss";
import AppImages from "../../AppImages";

export default function Logo({ url }) {
	//const { path } = useRouteMatch();
	const mainUrl = url || "/"; //path.indexOf("admin") > -1 ? "/admin" : "/";

	return !url ? (
		<Link to={mainUrl} className={classNames.root}>
			<div className={classNames.logo}>
				<img src={AppImages.AppLogo} alt="..." className={classNames.logo} />
			</div>
		</Link>
	) : (
		<a
			href={mainUrl}
			className={classNames.root}
			target="_blank"
			rel="noreferrer"
		>
			<div className={classNames.logo}>
				<img src={AppImages.AppLogo} alt="..." className={classNames.logo} />
			</div>
		</a>
	);
}
