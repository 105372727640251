import AccessDenied from "./assets/images/general/access-denied.svg";
import AppLogo from "./assets/images/app-logo-text.jpg";
import Instructions from "./assets/images/instructions.jpg";
import MagicHatImg from "./assets/images/mint-magic-hat.svg";
import NFTImg from "./assets/images/nfts/2.png";
import OpenSeaImg from "./assets/images/general/macbook-air.png";
import Ethereum from "./assets/images/general/ethereum.svg";
import Polygon from "./assets/images/general/polygon.svg";
import USDC from "./assets/images/general/usdc.svg";
import USDT from "./assets/images/general/usdt.svg";
import VITA from "./assets/images/general/vita.png";
import WelcomeImg from "./assets/images/general/welcome.gif";
import SearchingGif from "./assets/images/general/searching.gif";


import BankruptImage from "./assets/images/payment/bankrupt.svg";
import ErrorImage from "./assets/images/payment/error.svg";
import LoadingGif from "./assets/images/payment/loading.gif";
import EmptyImage from "./assets/images/payment/empty.svg";

// METAMASK STEPS MEDIA
import GeneratingIcon from "./assets/images/payment/loading.gif";
import NetworkIcon from "./assets/images/metamask-steps/network.svg";
import PaymentIcon from "./assets/images/metamask-steps/payment.svg";
import CompletedIcon from "./assets/images/metamask-steps/completed.svg";
import ActiveIcon from "./assets/images/metamask-steps/active.svg";
import InActiveIcon from "./assets/images/metamask-steps/inactive.svg";

// Help Page Images
import ViewNFT from "./assets/images/help/view-nft.png";
import WiewProfile from "./assets/images/help/view-profile.png";
import GetAddress from "./assets/images/help/get-address.png";
import ImportNFT from "./assets/images/help/import-nft.png";

const AppImages = {
	AppLogo,
	Instructions,
	AccessDenied,
	MagicHatImg,
	WelcomeImg,
	SearchingGif,
	
	NFTImg,
	OpenSeaImg,
	polygon: Polygon,
	ethereum: Ethereum,

	ETH: Ethereum,
	MATIC: Polygon,
	USDC: USDC,
	USDT: USDT,
	WETH: Ethereum,
	VITA: VITA,

	INSUFFICIENT_FUNDS_IMG: BankruptImage,
	PURCHASE_ERROR_IMG: ErrorImage,
	LOADING_GIF: LoadingGif,
	EMPTY_IMG: EmptyImage,

	METAMASK_STEPS_ICONS: {
		CompletedIcon,
		ActiveIcon,
		InActiveIcon,
		NetworkIcon,
		PaymentIcon,
		GeneratingIcon,
	},

	Help: {
		ViewNFT,
		WiewProfile,
		GetAddress,
		ImportNFT,
		OpenSeaImg
	}
};

export default AppImages;
