import React from "react";
import cx from "classnames";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

import s from "./Header.module.scss";

import AvatarWidget from "../AvatarWidget/AvatarWidget";
import HistoryIcon from "../icons/HistoryIcon";
import HomeIcon from "../icons/HomeIcon";
import Logo from "../Logo/Logo";
import HelpIcon from "../icons/HelpIcon";

export default function Header({
	user,
	goBack,
	showHome,
	hasAvatar = true,
	className = "",
}) {
	return (
		<div className={cx(s.root, className)}>
			{goBack ? (
				<Button
					className={cx(s.backButton, "btn-border")}
					color="info"
					onClick={goBack}
				>
					<i className="fa fa-arrow-left" />
					<span>Back</span>
				</Button>
			) : null}

			<div className={s.title}>
				<Logo />
			</div>

			{hasAvatar && <AvatarWidget user={user} />}

			<div className={s.iconsContainer}>
				{showHome ? (
					<Link to={"/"} className={s.icon}>
						<HomeIcon />
					</Link>
				) : null}

				{user ? (
					<Link to={"/admin/history"} className={s.icon}>
						<HistoryIcon />
					</Link>
				) : null}

				<Link to={"/help"} className={s.icon}>
					<HelpIcon />
				</Link>
			</div>
		</div>
	);
}
