import React from "react";
import cx from "classnames";
import { useHistory } from "react-router";
import { Button } from "reactstrap";

import s from "./AdminNFTPage.module.scss";

import AppConfig from "../../../AppConfig";
import AppMessages from "../../../AppMessages";
import Header from "../../../components/Header/Header";
//import NFTCard from "../../../components/NFTCard/NFTCard";
import OpenSeaCard from "../../../components/OpenSeaCard/OpenSeaCard";
import CopyWidget from "../../../components/CopyWidget/CopyWidget";
import Footer from "../../../components/Footer/Footer";

export default function AdminNFTPage({ user }) {
	const history = useHistory();

	const onMint = () => {
		history.push("/admin");
	};

	return (
		<div className={s.root}>
			<Header user={user} />

			<div className={s.content}>
				<div className={s.title}>
					You've sent a Proof of Philanthropy Dynamic NFT!
				</div>

				<div className={s.infos}>
					<div className={cx(s.description, s.mb)}>
						After the recipient makes donations to:
					</div>

					<CopyWidget text={AppConfig.DONATIONS_ADDRESS} />

					<div className={cx(s.description)}>
						they'll see their NFT power-up.
					</div>
					<div className={cx(s.description, s.mt, s.mb)}>
						Thanks for supporting our mission to fight aging!
					</div>
				</div>

				<div className={s.buttons}>
					<Button className={s.button} color="primary" onClick={onMint}>
						{AppMessages.ADMIN_NFT_BUTTON_TEXT}
					</Button>
				</div>

				{/* <NFTCard  /> */}

				<OpenSeaCard user={user} />

				<Footer />
			</div>
		</div>
	);
}
